import { useApolloClient } from '@app/context/ApolloClientContext';
import { NavigationScope } from '@app/context/NavigationContext';
import { useUser } from '@app/context/UserContext';
import { OnboardingApps } from '@app/context/constants';
import useChat from '@app/hooks/useChat';
import { useNavigation } from '@app/hooks/useNavigation';
import { Spinner } from '@components/ui';
import { CloseOutlined } from '@mui/icons-material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SendIcon from '@mui/icons-material/Send';
import { Backdrop, IconButton, Tooltip } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import LangSelect from '../Header/LangSelect';
import { Message } from './Message';
import { ChatTokenType } from './constants';
import dieter_kopf from '/assets/images/dieter_kopf.svg';

let interval: any;
let last_scroll_top: number;

interface Props {
  chatId: string;
}

interface ISuggestion {
  question: string;
  description: string;
}

export function Chat() {
  const { user } = useUser();
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const { isConnected } = useApolloClient();
  const history = useHistory();
  const {
    navigation: { isMobile, scope, hubspot },
    setNavigation,
  } = useNavigation();

  const isQuestScope = scope === 'quest';
  const isOnboardingScope = scope === 'onboarding';

  const baseSuggestions: ISuggestion[] = [
    {
      question: t('components.block.chat.suggestions.s1.question'),
      description: t('components.block.chat.suggestions.s1.desc'),
    },
    {
      question: t('components.block.chat.suggestions.s2.question'),
      description: t('components.block.chat.suggestions.s2.desc'),
    },
    {
      question: t('components.block.chat.suggestions.s3.question'),
      description: t('components.block.chat.suggestions.s3.desc'),
    },
  ];

  const SUGGESTIONS: Record<NavigationScope, ISuggestion[]> = {
    dashboard: baseSuggestions,
    quest_external: baseSuggestions,
    quest: [
      ...baseSuggestions,
      {
        question: t('components.block.chat.suggestions.s4.question'),
        description: t('components.block.chat.suggestions.s4.desc'),
      },
    ],
    onboarding: [
      {
        question: t('components.block.chat.suggestions.onboarding.dse.question'),
        description: t('components.block.chat.suggestions.onboarding.dse.desc'),
      },
      {
        question: t('components.block.chat.suggestions.onboarding.social.question'),
        description: t('components.block.chat.suggestions.onboarding.social.desc'),
      },
      {
        question: t('components.block.chat.suggestions.onboarding.info.question'),
        description: t('components.block.chat.suggestions.onboarding.info.desc'),
      },
      {
        question: t('components.block.chat.suggestions.onboarding.av.question'),
        description: t('components.block.chat.suggestions.onboarding.av.desc'),
      },
      {
        question: t('components.block.chat.suggestions.onboarding.toms.question'),
        description: t('components.block.chat.suggestions.onboarding.toms.desc'),
      },
      {
        question: t('components.block.chat.suggestions.onboarding.vvt.question'),
        description: t('components.block.chat.suggestions.onboarding.vvt.desc'),
      },
      {
        question: t('components.block.chat.suggestions.onboarding.checkup.question'),
        description: t('components.block.chat.suggestions.onboarding.checkup.desc'),
      },

      {
        question: t('components.block.chat.suggestions.onboarding.unknown.question'),
        description: t('components.block.chat.suggestions.onboarding.unknown.desc'),
      },
    ],
  };

  const baseWelcomeMessage = t('components.block.chat.hello');
  const welcomeMessage: Record<NavigationScope, React.ReactNode> = {
    onboarding:
      user?.source === 'mittwald' ? (
        <div>
          <p>{t('components.block.chat.hello_onboarding.p1')} 👋</p>
          <p className="font-normal">
            Ich freue mich, dass du den Weg von unserem Partner{' '}
            <span className="inline-flex items-center h-[1em] ">
              <MittwaldLogo className="h-[1em] w-auto align-middle text-[#27367b]" />
            </span>{' '}
            zu mir gefunden hast!
          </p>
          {t('components.block.chat.hello_onboarding.p2')}
        </div>
      ) : (
        <div>
          <p>{t('components.block.chat.hello_onboarding.p1')} 👋</p> {t('components.block.chat.hello_onboarding.p2')}
        </div>
      ),

    dashboard: baseWelcomeMessage,
    quest: baseWelcomeMessage,
    quest_external: baseWelcomeMessage,
  };

  const hubspotWidgetActive = window.HubSpotConversations?.widget?.status()?.loaded || false;

  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const latestChat = user?.chats?.reduce((acc, chat) => (chat.createdAt > acc.createdAt ? chat : acc), user.chats[0]);

  const chat = useChat({
    chatId: latestChat?.id || '',
    handlers: {
      onMessageAdded: () => {
        const win = ref.current;

        win?.addEventListener('scroll', () => {
          if (win.scrollTop !== last_scroll_top) clearInterval(interval);
        });
      },
    },
  });

  const handleClick = () => {
    chat.addChat();
  };

  useEffect(() => {
    setIsExpanded(isOnboardingScope);
  }, [isOnboardingScope]);

  // if no chat exists, create a new one
  useEffect(() => {
    if (!latestChat) chat.addChat();
  }, [latestChat]);

  // if onboarding scope always reset the chat on render
  useEffect(() => {
    if (isOnboardingScope) {
      chat.addChat();
    }
  }, [isOnboardingScope]);

  const vhToPixels = (vh: number) => {
    return Math.round(window.innerHeight * (vh / 100));
  };

  const vwToPixels = (vw: number) => {
    return Math.round(window.innerWidth * (vw / 100));
  };

  useEffect(() => {
    const win = ref.current;

    if (win && chat.isStreaming) {
      interval = setInterval(() => {
        win.scrollTo({ top: win.scrollHeight });
        last_scroll_top = win.scrollTop;
      }, 1);
    } else if (!chat.isStreaming && interval) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [chat.isStreaming]);

  const shouldDisableInput = chat.isStreaming || !chat.input.split('\n').filter(Boolean).length;

  const styles = useSpring({
    height: isExpanded ? vhToPixels(isMobile ? 95 : 80) : 80,
    // width: isExpanded ? vwToPixels(60) : vwToPixels(100),
    // backgroundColor: isExpanded ? 'rgba(255, 255, 255, 1)' : '',
    // bottom: isExpanded ? '20px' : '80px',
    // backdropFilter: isExpanded ? 'blur(10px)' : 'none',
  });

  const tokenActions: Record<ChatTokenType, () => void> = {
    [ChatTokenType.ContactEmployee]: () => {
      setNavigation((draft) => void (draft.hubspot.showChat = true));
      setIsExpanded(false);
      // if hubspot is already loaded, open the chat
      if (hubspotWidgetActive) {
        window.HubSpotConversations?.widget?.open();
      }
    },
    [ChatTokenType.OnboardingDSE]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.DSE }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingTOMs]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.TOMs }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingCheckup]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.CHECKUP }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingVVT]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.VVT }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingVVTMittwald]: () => {},
    [ChatTokenType.OnboardingAVV]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.AVV }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingAVVMittwald]: () => {},
    [ChatTokenType.OnboardingImprint]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.IMPRINT }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingInfo]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.INFO }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingFacebook]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.FACEBOOK }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingInstagram]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.INSTAGRAM }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingLinkedIn]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.LINKEDIN }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingTikTok]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.TIKTOK }));
      history.push('/dashboard');
      chat.addChat();
    },
    [ChatTokenType.OnboardingXING]: () => {
      setNavigation((draft) => void (draft.onboarding = { inProgress: true, appId: OnboardingApps.XING }));
      history.push('/dashboard');
      chat.addChat();
    },
  };

  const handleSend = () => {
    chat.submitMessage(chat.input);
    inputRef.current?.attributeStyleMap.set('height', '52px');
  };

  return (
    <>
      <animated.div
        style={styles}
        className={cx(
          'max-h-[95vh] max-w-[800px] fixed z-[1240] overflow-hidden rounded-t-2xl md:shadow-[0px_2px_6px_-1px_rgba(0,0,0,0.2)]',
          hubspotWidgetActive && !isExpanded
            ? 'w-[75vw] md:w-[85vw] lg:w-[95vw] left-0 lg:left-1/2 lg:-translate-x-1/2'
            : 'w-full left-1/2 -translate-x-1/2',

          isExpanded ? 'bg-white z-[1000000]' : isQuestScope ? ' bg-gray-100' : 'bg-primary-100',
          !isExpanded ? '-bottom-3 md:bottom-0 ' : 'bottom-0' //  border-gray-200
        )}
      >
        <div className={'w-full h-full flex flex-col absolute left-1/2 -translate-x-1/2'}>
          {/* <Button onClick={() => setNavigation((draft) => void (draft.showHubspotChat = true))}>HUBSPOT</Button> */}
          {isExpanded && (
            <>
              <header className="border-b border-gray-200 relative flex items-center space-x-3 bg-white px-6 py-5 rounded-lg flex-wrap sm:flex-nowrap">
                <div className="absolute top-0 right-0">
                  <IconButton onClick={() => setIsExpanded(false)}>
                    <CloseOutlined fontSize="large" />
                  </IconButton>
                </div>
                <div className="flex-shrink-0">
                  <img className="h-14 w-14 rounded-full" src={dieter_kopf} alt="Avatar of Dieter" />
                </div>

                <div className="min-w-0 ">
                  <span className="text-sm font-medium text-gray-900">Dieter</span>
                  <span className="truncate text-sm text-gray-500 flex gap-1 items-center">
                    <span data-testid="chat-status-indicator">{isConnected ? 'online' : 'offline'}</span>
                    <span className={cx('h-2 w-2 rounded-full', isConnected ? 'bg-green-500' : 'bg-red-500')}></span>
                  </span>
                </div>
                <IconButton onClick={handleClick}>
                  <Tooltip title="Chat zurücksetzen" arrow>
                    <RestartAltIcon />
                  </Tooltip>
                </IconButton>
                <LangSelect inHeader={false} />
                <div className="grow sm:flex sm:justify-end">
                  <Trans t={t} i18nKey={'components.block.chat.privacy'}>
                    <div className="text-[0.65rem] max-w-none sm:max-w-[200px] text-gray-500 text-center sm:text-right sm:mr-6 mt-2 sm:mt-0">
                      Bei der Benutzung des Dieter-Chatbots gilt unsere{' '}
                      <a href="/privacy" target="_blank" rel="noreferrer">
                        Datenschutzerklärung
                      </a>
                      .
                    </div>
                  </Trans>
                </div>
              </header>
              <main ref={ref} className="relative flex flex-col flex-grow overflow-y-scroll mb-2 space-y-4 px-10 py-5">
                {chat.messages.length ? (
                  <>
                    {chat.messages.map((message, idx) => (
                      <div
                        key={message.id}
                        data-testid={`chat-message-${idx}`}
                        className={`p-3 rounded-lg max-w-lg ${
                          message.role === 'assistant' ? 'bg-primary-100' : 'bg-secondary-100 ml-auto'
                        }`}
                      >
                        <Message tokenActions={tokenActions}>{message.content}</Message>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="relative w-full h-full">
                    <div className="inset-0 flex justify-center">
                      <h3 className="text-lg font-bold text-center mb-20">
                        {isConnected && scope ? (
                          welcomeMessage[scope]
                        ) : (
                          <div className="flex gap-2 items-center">
                            <span>{t('components.block.chat.not_available')}</span>
                            <Spinner size="small" />
                          </div>
                        )}
                      </h3>
                    </div>
                    <div
                      className={cx(
                        'inset-0 grid grid-cols-1  gap-2 lg:gap-4 place-content-end',
                        isQuestScope || isOnboardingScope ? 'lg:grid-cols-2' : 'lg:grid-cols-3'
                      )}
                    >
                      {scope &&
                        isConnected &&
                        SUGGESTIONS[scope].map((suggestion, idx) => (
                          <div
                            key={suggestion.question}
                            data-testid={`chat-suggestion-${idx}`}
                            onClick={() => chat.submitMessage(suggestion.question)}
                            className="cursor-pointer rounded-lg border border-gray-300 bg-white space-y-1 lg:space-y-2  px-3 py-2.5 lg:px-6 lg:py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-700"
                          >
                            <h6 className="text-sm font-bold">{suggestion.question}</h6>
                            <p className="text-xs">{suggestion.description}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </main>
            </>
          )}
          <footer className="flex w-full items-center">
            <div
              // ref={formRef}
              // onSubmit={(e) => {
              //   e.preventDefault();
              //   inputRef.current?.attributeStyleMap.set('height', '52px');
              //   return chat.handleSubmit(e);
              // }}
              className="flex w-full items-center px-3 py-3"
            >
              <div className="overflow-hidden flex flex-col w-full flex-grow relative items-center">
                <TextareaAutosize
                  ref={inputRef}
                  onFocus={() => setIsExpanded(true)}
                  data-testid="chat-input"
                  onKeyDown={(e) => {
                    if (!e.shiftKey && e.key === 'Enter') {
                      e.preventDefault();
                      chat.input.trim() && handleSend();
                    }
                  }}
                  placeholder={isQuestScope ? t('components.block.chat.prompt') : t('components.block.chat.prompt2')}
                  className={cx(
                    'h-11 m-0 w-full resize-none border-2  focus:outline-0 focus:ring-0 focus-visible:ring-0 py-[10px] pr-10 md:py-3.5 md:pr-12 max-h-52 placeholder-black/50 pl-3 md:pl-4 bg-white rounded-lg overflow-clip text-base',
                    isQuestScope ? 'border-gray-300' : 'border-primary-root'
                  )}
                  value={chat.input}
                  onChange={chat.handleInputChange}
                  // disabled={!isConnected}
                />
                <button
                  // type="submit"
                  onClick={handleSend}
                  className="absolute bottom-1.5 right-2 rounded-lg border border-primary-root bg-primary-root p-0.5 text-white transition-colors enabled:bg-primary-root disabled:text-gray-400 disabled:opacity-10 md:bottom-3 md:right-3"
                  disabled={shouldDisableInput}
                  data-testid="chat-send-button"
                >
                  <span className="" data-state="closed">
                    <SendIcon className="w-6 h-6 fill-current" />
                  </span>
                </button>
              </div>
            </div>
          </footer>
        </div>
      </animated.div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isExpanded}
        onClick={() => setIsExpanded(false)}
      />
    </>
  );
}

function MittwaldLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      // style="enable-background:new 0 0 2560 906.4;"
      // xml:space="preserve"
      viewBox="275 250.7 2081.8 386.4"
    >
      {/* <style type="text/css">
	.st0{fill:#FFF;}
</style> */}
      <g fill="#27367b">
        <path
          className="st0"
          d="M602.9,358.3c-30.6,0-67.5,15.5-86.8,47.7c-13.9-30-40.2-47.7-82.6-47.7c-30.2,0-60,15.2-76.4,37l-8.9-45.1   L275,364.6l8.9,45.4l0,0.7l0,221h71.8V492.9c0-35.4,18.2-66.5,54.7-66.5c38.6,0,46.6,26.3,46.6,61.6v143.7h71.8V490.2   c0-35.4,19.3-63.8,54.7-63.8c38.1,0,46.6,26.3,46.6,61.6v149l71.8-14.1V478.9C702.1,410.3,678.5,358.3,602.9,358.3z"
        />
        <path
          className="st0"
          d="M773.5,250.7c-24.7,0-45.4,20.7-45.4,46c0,24.7,20.7,45.4,45.4,45.4c25.3,0,46-20.7,46-45.4   C819.5,271.4,798.8,250.7,773.5,250.7z"
        />
        <rect x="738.1" y="363.5" className="st0" width="71.8" height="268.2" />
        <path
          className="st0"
          d="M1132.6,574.9c-12.9,0-24.1-7.5-24.1-30.6V421.6h74.1l-18.5-57.9h-55.6v-86.6l-71.8,14.1v72.5H1017h-19.5   h-54.5v-86.6l-71.8,14.1v72.5h-39.1v57.9h39.1v123.8c0,60.6,28.9,91.7,82.6,91.7c28.4,0,52-9.6,68.1-20.4l-20.4-53.1   c-2.1,1.6-17.2,11.3-34.3,11.3c-12.9,0-24.1-7.5-24.1-30.6V421.6h54.5h19.5h19.6v123.8c0,60.6,28.9,91.7,82.6,91.7   c28.4,0,52-9.6,68.1-20.4l-20.4-53.1C1164.8,565.3,1149.8,574.9,1132.6,574.9z"
        />
        <path
          className="st0"
          d="M1505.3,363.7L1464,506.8c-5.4,18.8-8,39.5-8,39.5s-2.7-20.8-7-39l-32.7-143.7h-70.8l-32.7,143.7   c-4.3,18.2-6.4,39-6.4,39s-3.2-20.8-8.6-39.5l-40.7-143.1h-70.2l85.8,268.6h65.4l35.9-144.7c4.3-18.2,7.5-37.9,7.5-37.9   s2.7,19.7,7,37.9l35.9,144.7h64.9l85.8-268.6H1505.3z"
        />
        <path
          className="st0"
          d="M1769.1,396.4c-19.3-25.7-52.5-38.1-83.6-38.1c-74,0-121.2,62.2-121.2,139.4s47.2,139.4,121.2,139.4   c31.1,0,64.9-11.8,83.6-37.5v37.4l70.2-13.7V363.7h-70.2V396.4z M1703.7,570.6c-38.6,0-66.5-30.6-66.5-72.9   c0-41.8,27.9-72.9,66.5-72.9c37,0,63.8,31.1,63.8,72.9C1767.5,540.6,1740.7,570.6,1703.7,570.6z"
        />
        <polygon className="st0" points="1875.3,631.7 1947.1,631.7 1947.1,276.9 1875.3,291.1  " />
        <path
          className="st0"
          d="M2177.4,393.2c-19.3-23.1-49.9-35.4-80.9-35.4c-74,0-122.2,62.2-122.2,139.4s47.2,139.4,121.2,139.4   c32.2,0,66.5-11.8,85.8-37.5v37.7l68.1-14.1v-337h-71.8V393.2z M2113.6,570.1c-38.6,0-66.5-30.6-66.5-72.9   c0-41.8,27.9-72.9,66.5-72.9c37,0,63.8,31.1,63.8,72.9C2177.4,540.1,2150.5,570.1,2113.6,570.1z"
        />
        <rect x="2285" y="559.5" className="st0" width="71.8" height="71.8" />
      </g>
    </svg>
  );
}
