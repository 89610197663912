import { gql, useMutation } from '@apollo/client';
import { Locales } from '@components/block/Header/LangSelect';
import { USER_FIELDS, User } from './useUserQuery';
const CREATE_USER = gql`
  ${USER_FIELDS}
  mutation CreateUser(
    $email: String
    $password: String
    $locale: String
    $wixId: String
    $whitelabelConfigId: String
    $celloReferralCode: String
  ) {
    createUser(
      email: $email
      password: $password
      locale: $locale
      wixId: $wixId
      whitelabelConfigId: $whitelabelConfigId
      celloReferralCode: $celloReferralCode
    ) {
      ...UserFields
      token
    }
  }
`;

export interface UserCreateInput {
  email?: string;
  password?: string;
  locale?: Locales;
  wixId?: string;
  whitelabelConfigId?: string;
  celloReferralCode?: string;
}

export interface UserCreateResult {
  createUser: User;
}

export function useUserCreateMutation() {
  return useMutation<UserCreateResult, UserCreateInput>(CREATE_USER, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getUser: () => (data && data.createUser ? data.createUser : null),
        },
      });
    },
  });
}
