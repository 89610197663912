import { Skeleton } from '@mui/material';
import { load } from 'cheerio';
import { marked } from 'marked';
import { useTranslation } from 'react-i18next';
import { ChatTokenType } from './constants';

interface Props {
  children: string;
  tokenActions?: Record<ChatTokenType, () => void>;
}

export function Message({ children, tokenActions }: Props) {
  const { t } = useTranslation();
  if (!children) return <Skeleton variant="text" width="100%" height="100%" />;

  const renderContent = (text: string) => {
    const parts = text.split(/\|\|\|(.+?)\|\|\|/);

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const token = part as ChatTokenType;
        switch (token) {
          case ChatTokenType.ContactEmployee:
            return (
              <button
                key={index}
                className="bg-secondary-root text-black px-4 py-2 rounded-md mt-4 hover:bg-secondary-300"
                onClick={tokenActions?.[ChatTokenType.ContactEmployee]}
              >
                {t('components.block.chat.message.button')}
              </button>
            );
          case ChatTokenType.OnboardingDSE:
          case ChatTokenType.OnboardingTOMs:
          case ChatTokenType.OnboardingCheckup:
          case ChatTokenType.OnboardingVVT:
          case ChatTokenType.OnboardingAVV:
          case ChatTokenType.OnboardingImprint:
          case ChatTokenType.OnboardingInfo:
          case ChatTokenType.OnboardingFacebook:
          case ChatTokenType.OnboardingInstagram:
          case ChatTokenType.OnboardingLinkedIn:
          case ChatTokenType.OnboardingTikTok:
          case ChatTokenType.OnboardingXING:
            return (
              <button
                key={index}
                className="bg-secondary-root text-black px-4 py-2 rounded-md mt-4 hover:bg-secondary-300 w-full max-w-[400px]"
                onClick={tokenActions?.[token]}
              >
                {t('components.block.chat.letsgo')}
              </button>
            );

          case ChatTokenType.OnboardingAVVMittwald:
          case ChatTokenType.OnboardingVVTMittwald:
            return (
              <div className="flex flex-col gap-4">
                <button
                  key={index}
                  className="bg-secondary-root text-black px-4 py-2 rounded-md mt-4 hover:bg-secondary-300 w-full max-w-[400px]"
                  onClick={() => window.open('https://www.mittwald.de/services/av-manager', '_blank')}
                >
                  {t('components.block.chat.letsgomittwald')}
                </button>
                <p>
                  Wenn dir Dieter stattdessen dabei helfen soll, dann geht es{' '}
                  <button className="underline text-primary-root" onClick={tokenActions?.ONBOARDING_AV}>
                    hier
                  </button>{' '}
                  entlang.
                </p>
              </div>
            );

          default:
            const _exhaustiveCheck: never = token;
            return part; // in case the token doesn't match any type
        }
      } else {
        // Convert markdown to HTML for the non-token parts
        const htmlPart = marked(part);

        // Use cheerio to manipulate the HTML
        const $ = load(htmlPart as string);

        // loop through all a tags and make sure they open in a new tab
        $('a').each(function () {
          $(this).attr('target', '_blank');
        });

        // Get the modified HTML
        const modifiedHtml = $.html();

        return <span key={index} dangerouslySetInnerHTML={{ __html: modifiedHtml }} />;
      }
    });
  };

  return <div className="text-gray-800 text-sm break-words markdown">{renderContent(children)}</div>;
}
