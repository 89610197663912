import { OnboardingApps } from '@app/context/constants';
import { OnboardingAppMapping } from '@app/context/NavigationContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { Button } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { Balancer } from 'react-wrap-balancer';

type Snippet = { title: string; text: string };

export function DocumentCallToAction({ onClose, minimal = false }: { onClose?: () => void; minimal?: boolean }) {
  const { t } = useTranslation();
  const {
    navigation: { onboarding },
  } = useNavigation();
  const appId: OnboardingApps = onboarding.appId || OnboardingApps.CHECKUP;

  const Snippets: OnboardingAppMapping<Snippet> = {
    [OnboardingApps.CHECKUP]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.checkup.title'),
      text: t('components.block.OutputView.DocumentCallToAction.snippets.checkup.text'),
    },
    [OnboardingApps.DSE]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.dse.title'),
      text: '',
    },
    [OnboardingApps.FACEBOOK]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.dse.title'),
      text: '',
    },
    [OnboardingApps.INSTAGRAM]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.dse.title'),
      text: '',
    },
    [OnboardingApps.LINKEDIN]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.dse.title'),
      text: '',
    },
    [OnboardingApps.TIKTOK]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.dse.title'),
      text: '',
    },
    [OnboardingApps.XING]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.dse.title'),
      text: '',
    },
    [OnboardingApps.TOMs]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.toms.title'),
      text: '',
    },
    [OnboardingApps.IMPRINT]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.imprint.title'),
      text: '',
    },
    [OnboardingApps.VVT]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.vvt.title'),
      text: '',
    },
    [OnboardingApps.AVV]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.avv.title'),
      text: '',
    },
    [OnboardingApps.INFO]: {
      title: t('components.block.OutputView.DocumentCallToAction.snippets.info.title'),
      text: '',
    },
  };

  return (
    <div className="bg-secondary-root p-5 flex flex-col gap-20 mt-5">
      <div className="flex justify-between flex-col md:flex-row gap-10 items-center">
        <strong className="text-lg grow">{Snippets[appId]?.title}</strong>
        {minimal && (
          <div className="self-center w-full md:w-auto">
            <Button
              btnColor="primary-dark"
              onClick={onClose}
              googleTriggerClassName="ga4-event-checkup-cta"
              data-testid="button-cta"
            >
              {t('components.block.OutputView.DocumentCallToAction.button')}
            </Button>
          </div>
        )}
      </div>
      {!minimal && (
        <div className="flex flex-col md:flex-row gap-20 justify-between">
          {Snippets[appId]?.text && (
            <p>
              <Balancer>{Snippets[appId]?.text}</Balancer>
            </p>
          )}
          <div className="md:self-end self-center w-full md:w-auto">
            <Button
              btnColor="primary-dark"
              onClick={onClose}
              googleTriggerClassName="ga4-event-checkup-cta"
              data-testid="button-cta"
            >
              {t('components.block.OutputView.DocumentCallToAction.button')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
