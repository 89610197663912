import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { useEffect } from 'react';

export function Cello() {
  const { user } = useUser();
  const { navigation } = useNavigation();
  const isDev = import.meta.env.MODE === 'development';
  const celloActive = import.meta.env.VITE_CELLO_ACTIVE === 'true';

  useEffect(() => {
    if (!celloActive) return;
    const script = document.createElement('script');
    script.type = 'module';
    script.src = import.meta.env.VITE_CELLO_WIDGET_LIB;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
      window.Cello?.('shutdown');
    };
  }, []);

  useEffect(() => {
    if (!celloActive) return;
    if (!user?.celloToken || !user?.celloActive || !navigation.isDieterHost) return;
    window.cello = window.cello || { cmd: [] };

    // only add the cello boot command if the list ist empty
    if (window.cello.cmd.length > 0) return;
    window.cello.cmd.push(async function (cello: any) {
      try {
        await cello.boot({
          productId: user?.celloProductId,
          token: user?.celloToken,
          language: user?.locale,
          productUserDetails: {
            email: user?.email,
          },
        });
      } catch (error) {
        console.error('Failed to boot cello:', error);
        // Handle the error appropriately
      }
    });
  }, [user?.celloToken, user?.celloActive]);
  return <></>;
}
