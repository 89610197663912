import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { USER_FIELDS, User } from './useUserQuery';

const LOGIN_MITTWALD_USER = gql`
  ${USER_FIELDS}
  mutation LoginMittwaldUser($userId: String!, $instanceId: String!, $retrievalKey: String!) {
    loginMittwaldUser(userId: $userId, instanceId: $instanceId, retrievalKey: $retrievalKey) {
      ...UserFields
      token
    }
  }
`;

export interface UserLoginMittwaldInput {
  userId: string;
  instanceId: string;
  retrievalKey: string;
}

export interface UserLoginResult {
  loginMittwaldUser: User;
}

export function useUserLoginMittwaldMutation() {
  const { i18n } = useTranslation();
  return useMutation<UserLoginResult, UserLoginMittwaldInput>(LOGIN_MITTWALD_USER, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getUser: () => data?.loginMittwaldUser || null,
        },
      });
    },
    onCompleted(data) {
      if (!data?.loginMittwaldUser) return;
      i18n.changeLanguage(data.loginMittwaldUser.locale);
    },
  });
}
