import { useUser } from '@app/context/UserContext';
import { OnboardingApps } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { Chat } from '@components/block/Chat/Chat';
import { Locales } from '@components/block/Header/LangSelect';
import { Loading } from '@components/ui';
import { useUserCreateMutation } from '@dieterApi/user/useUserCreateMutation';
import { useUserLoginMutation } from '@dieterApi/user/useUserLoginMutation';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

// type as generic to avoid type errors
const AppIdUrlMap: {
  [key: string]: OnboardingApps;
} = {
  checkup: OnboardingApps.CHECKUP,
  datenschutzerklaerung: OnboardingApps.DSE,
  toms: OnboardingApps.TOMs,
  impressum: OnboardingApps.IMPRINT,
  vvt: OnboardingApps.VVT,
  avv: OnboardingApps.AVV,
  info: OnboardingApps.INFO,
};

export function Onboarding() {
  const { i18n } = useTranslation();
  const { user, userLoading } = useUser();
  const {
    params: { appTitleUrl },
  } = useRouteMatch<{ appTitleUrl?: string }>();
  const history = useHistory();
  const { setNavigation, navigation } = useNavigation();

  const { enqueueSnackbar } = useSnackbar();
  const [createUser] = useUserCreateMutation();

  const appId = AppIdUrlMap[appTitleUrl || 'checkup'];
  const [login, { loading }] = useUserLoginMutation();

  // Get query parameter "authtoken" from URL
  const queryParams = new URLSearchParams(location.search);
  const authtoken = queryParams.get('authtoken');
  // When a user comes from Wix we need to get the wix_id to link the user
  const wixId = queryParams.get('wix_id');
  // this is for whitelabel customer onboarding
  const whitelabelConfigId = queryParams.get('wlc_id');
  // when a user is a cello referral
  const celloReferralCode = queryParams.get('ucc');
  const celloProductId = queryParams.get('productId');

  // Get first two letters of language code
  const langString = i18n.language.slice(0, 2) as Locales;

  useEffect(() => {
    // If authtoken is present, use it to log in the user
    if (authtoken && authtoken !== 'without') {
      login({
        variables: {
          email: '',
          password: '',
          token: authtoken,
          locale: langString,
        },
      }).catch((e) =>
        // TODO/WM/ASK: it will be better for "multi lang" to map error codes with messages
        enqueueSnackbar(e.message, { variant: 'error', stack: e.stack })
      );
    }
  }, [authtoken]);

  useEffect(() => {
    if (!user) return;

    setNavigation((nav) => {
      nav.scope = 'onboarding';
    });

    if (appTitleUrl || whitelabelConfigId) {
      const app = user?.topics.flatMap((t) => t.applications).find((a) => a.id === (appId || 'app-checkup'));
      if (app) {
        // send view_item event to GA4
        if (window.dataLayer) {
          let value: number;
          switch (app?.id) {
            case 'app-checkup':
              value = 348;
              break;
            case 'app-dse-privacy-policy-create':
              value = 84;
              break;
            default:
              value = 180;
              break;
          }

          window.dataLayer.push({
            event: 'ga4-event-view-item',
            ordervalue: value,
            email: user?.email,
          });
        }
        setNavigation((nav) => void (nav.onboarding = { inProgress: true, appId: app.id as OnboardingApps }));
      }
    }
  }, [user]);

  useEffect(() => {
    if (!navigation.onboarding.inProgress) return;
    if (window.tracify) {
      // send product view event to tracify
      window.tracify.captureProductView();
    }
    history.push('/dashboard');
  }, [navigation.onboarding.inProgress]);

  useEffect(() => {
    // skip onboarding if this is not the first company of a user or there are already questionnaires
    if ((user?.companies.length || 0) > 1 || user?.questionnaires?.length) {
      history.push('/dashboard');
    }
  }, [user?.companies.length]);

  // create anonymous user when entering the onboarding process
  useEffect(() => {
    if (userLoading) return;
    if (!user && !authtoken) {
      createUser({
        variables: {
          locale: i18n.language.slice(0, 2) as Locales,
          wixId: wixId || undefined,
          whitelabelConfigId: whitelabelConfigId || undefined,
          celloReferralCode: celloReferralCode || undefined,
        },
      }).catch((e) => enqueueSnackbar(e.message, { variant: 'error', stack: e.stack }));
    }
  }, [user, userLoading]);

  return !user || userLoading || appTitleUrl ? (
    <div className="mt-24">
      <Loading />
    </div>
  ) : (
    <div>
      <img
        src="/assets/images/dieter_winkt.svg"
        alt="Dieter winkt"
        className="max-h-[70vh] h-[600px] absolute bottom-20 left-1/2 -translate-x-1/2"
      />
      <Chat />
    </div>
  );
}
