import { Checkbox, FormControlLabel, Modal, Paper } from '@mui/material';

import { useUser } from '@app/context/UserContext';
import { Button, Field, Input } from '@components/ui';

import { useApolloClient } from '@app/context/ApolloClientContext';
import { OnboardingAppMapping } from '@app/context/NavigationContext';
import { OnboardingApps } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { useConfirmSendEmailMutation } from '@dieterApi/user/useUserConfirmSendMutation';
import { useUserLogoutMutation } from '@dieterApi/user/useUserLogoutMutation';
import { useUserUpdateEmailMutation } from '@dieterApi/user/useUserUpdateEmailMutation';
import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import Balancer from 'react-wrap-balancer';

type Snippet = { title: string; text: string; disclaimer: string };

export function EmailModal() {
  const { t } = useTranslation();
  const {
    navigation: { animate, onboarding },
    setNavigation,
  } = useNavigation();

  const { user } = useUser();
  const [email, setEmail] = useState(user?.email || '');
  const { client } = useApolloClient();
  const [emailModalOpen, setEmailModalOpen] = useState(!user?.email || !user?.confirmed);
  const [updateEmail, { loading: updateLoading }] = useUserUpdateEmailMutation();
  const [sendConfirmationEmail, { loading: sendLoading }] = useConfirmSendEmailMutation();
  const [logout] = useUserLogoutMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState('');
  const appId = onboarding.appId || OnboardingApps.CHECKUP;

  const Snippets: OnboardingAppMapping<Snippet> = {
    [OnboardingApps.CHECKUP]: {
      title: t('route.dashboard.email-modal.app-checkup.title'),
      text: t('route.dashboard.email-modal.app-checkup.text'),
      disclaimer: t('route.dashboard.email-modal.app-checkup.disclaimer'),
    },
    [OnboardingApps.DSE]: {
      title: t('route.dashboard.email-modal.app-dse-privacy-policy-create.title'),
      text: t('route.dashboard.email-modal.app-dse-privacy-policy-create.text'),
      disclaimer: t('route.dashboard.email-modal.app-dse-privacy-policy-create.disclaimer'),
    },
    [OnboardingApps.FACEBOOK]: {
      title: t('route.dashboard.email-modal.app-dse-privacy-policy-create.title'),
      text: t('route.dashboard.email-modal.app-dse-privacy-policy-create.text'),
      disclaimer: t('route.dashboard.email-modal.app-dse-privacy-policy-create.disclaimer'),
    },
    [OnboardingApps.INSTAGRAM]: {
      title: t('route.dashboard.email-modal.app-dse-privacy-policy-create.title'),
      text: t('route.dashboard.email-modal.app-dse-privacy-policy-create.text'),
      disclaimer: t('route.dashboard.email-modal.app-dse-privacy-policy-create.disclaimer'),
    },
    [OnboardingApps.LINKEDIN]: {
      title: t('route.dashboard.email-modal.app-dse-privacy-policy-create.title'),
      text: t('route.dashboard.email-modal.app-dse-privacy-policy-create.text'),
      disclaimer: t('route.dashboard.email-modal.app-dse-privacy-policy-create.disclaimer'),
    },
    [OnboardingApps.TIKTOK]: {
      title: t('route.dashboard.email-modal.app-dse-privacy-policy-create.title'),
      text: t('route.dashboard.email-modal.app-dse-privacy-policy-create.text'),
      disclaimer: t('route.dashboard.email-modal.app-dse-privacy-policy-create.disclaimer'),
    },
    [OnboardingApps.XING]: {
      title: t('route.dashboard.email-modal.app-dse-privacy-policy-create.title'),
      text: t('route.dashboard.email-modal.app-dse-privacy-policy-create.text'),
      disclaimer: t('route.dashboard.email-modal.app-dse-privacy-policy-create.disclaimer'),
    },
    [OnboardingApps.TOMs]: {
      title: t('route.dashboard.email-modal.app-toms-create.title'),
      text: t('route.dashboard.email-modal.app-toms-create.text'),
      disclaimer: t('route.dashboard.email-modal.app-toms-create.disclaimer'),
    },
    [OnboardingApps.IMPRINT]: {
      title: t('route.dashboard.email-modal.app-dse-imprint-create.title'),
      text: t('route.dashboard.email-modal.app-dse-imprint-create.text'),
      disclaimer: t('route.dashboard.email-modal.app-dse-imprint-create.disclaimer'),
    },
    [OnboardingApps.VVT]: {
      title: t('route.dashboard.email-modal.app-vvt.title'),
      text: t('route.dashboard.email-modal.app-vvt.text'),
      disclaimer: t('route.dashboard.email-modal.app-vvt.disclaimer'),
    },
    [OnboardingApps.AVV]: {
      title: t('route.dashboard.email-modal.app-avv.title'),
      text: t('route.dashboard.email-modal.app-avv.text'),
      disclaimer: t('route.dashboard.email-modal.app-avv.disclaimer'),
    },
    [OnboardingApps.INFO]: {
      title: t('route.dashboard.email-modal.app-info.title'),
      text: t('route.dashboard.email-modal.app-info.text'),
      disclaimer: t('route.dashboard.email-modal.app-info.disclaimer'),
    },
  };

  const animateEmailModal = useSpring({
    transform: animate ? 'translateX(150%)' : 'translateX(0%)',
    onRest: () => {
      if (animate) {
        setEmailModalOpen(false);
        setNavigation((nav) => void (nav.animate = false));
      }
    },
  });

  const handleClick = () => {
    if (!email) {
      setError(t('route.dashboard.email-modal.missing-email-error', 'Bitte gib deine E-Mail-Adresse ein.'));
      return;
    }

    updateEmail({
      variables: { email, marketingConsent: checked },
    })
      .then(() => {
        setError('');
        handleSendConfirmation();
        // track matomo event
        window._paq?.push(['trackEvent', 'funnel', 'account_created', 'email_set']);
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  const handleSendConfirmation = () => {
    sendConfirmationEmail({
      variables: { email },
    }).catch((e) => {
      setError(e.message);
    });
  };

  const handleMarketingCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const handleResetEmail = () => {
    user?.email && updateEmail({ variables: { email: user.email, reset: true } });

    setError('');
    setEmail('');
  };

  return (
    <Modal
      data-testid="email-model"
      open={emailModalOpen}
      onClose={() => setEmailModalOpen(false)}
      hideBackdrop={true}
      sx={{ zIndex: 50 }}
    >
      <animated.div style={animateEmailModal} className="h-screen pointer-events-none -z-50">
        <Paper
          className="h-full md:h-auto w-full md:w-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[600px] absolute top-1/2 left-1/2 overflow-visible z-50 pointer-events-auto"
          elevation={10}
        >
          <div className={cx('p-5 md:p-11 flex flex-col gap-2 relative h-full justify-center')}>
            <img
              src="/assets/images/dieter_kopf.svg"
              className="hidden md:absolute -top-[70px] z-50 h-28 left-1/2 -translate-x-1/2"
            />
            {/* This is the block that is shown when the user has not yet entered his email address. */}
            {!user?.email ? (
              <div className="mb-24 md:mb-0">
                <h1 className="text-2xl font-bold text-center my-5">
                  <Balancer>{Snippets[appId]?.title}</Balancer>
                </h1>
                <p className="text-center mb-5">
                  <Balancer>{Snippets[appId]?.text}</Balancer>
                </p>
                <form>
                  <Field htmlFor="email" label={t('common.form_field.email.label', 'E-Mail')}>
                    <Input
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder={t('common.form_field.email.placeholder', 'deine@email.de')}
                      value={email}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleClick();
                        }
                      }}
                      autoFocus={true}
                      data-testid="input-email"
                    ></Input>
                  </Field>
                </form>
                <p className="text-gray-500 text-xs">{Snippets[appId]?.disclaimer}</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple={true}
                      onChange={handleMarketingCheckbox}
                      value={checked}
                      required={false}
                    />
                  }
                  label={
                    <div className="text-gray-500 text-xs">
                      <span>
                        {t(
                          'route.dashboard.email-modal.marketing-request',
                          'Ich bin damit einverstanden, dass die simply Legal GmbH mir in diesem Zusammenhang Informationen, Praxistipps, Angebote und Updates rund um das Thema Datenschutz und verwandter Rechtsgebiete zusendet. Dieser Zustimmung kann jederzeit formlos widersprochen werden.'
                        )}
                      </span>
                    </div>
                  }
                />

                <p className="text-red-500 text-sm">{error}</p>
                <div className="my-5">
                  <Button
                    spinner={updateLoading || sendLoading}
                    onClick={handleClick}
                    data-testid="button-submit"
                    googleTriggerClassName="ga4-event-email-submit"
                  >
                    {t('common.continue', 'Weiter')}
                  </Button>
                </div>
                <div className="d-flex justify-between">
                  <Link to="/privacy" target="_blank" className="text-sm">
                    {t('common.privacy-policy', 'Datenschutzrichtlinie')}
                  </Link>
                  <Link to="#" className="text-sm" onClick={() => logout()}>
                    {t('common.to-login', 'Zum Login')}
                  </Link>
                </div>
              </div>
            ) : (
              // This is the block asking the user to confirm his email address.
              <div className="flex flex-col gap-5 mb-24 md:mb-0">
                <h1 className="text-2xl font-bold text-center">
                  <Balancer>{t('route.dashboard.email-modal.title')}</Balancer>
                </h1>

                <p className="text-center">
                  <Balancer>
                    {t(
                      'route.dashboard.email-modal.subtitle',
                      'Klicke auf den Link in der E-Mail, die wir dir gerade gesendet haben. Bitte überprüfe auch deinen Spam-Ordner.'
                    )}
                  </Balancer>
                </p>

                {error !== '' && <p className="text-red-500 text-sm">{error}</p>}

                <Link to="#" onClick={handleResetEmail} className="text-center">
                  {t('route.dashboard.email-modal.enter-address-again', 'Adresse erneut eingeben')}
                </Link>

                <Button
                  className="mt-1"
                  spinner={updateLoading || sendLoading}
                  onClick={() => {
                    updateEmail({
                      variables: { email },
                    })
                      .then(() => {
                        enqueueSnackbar('E-Mail wurde gesendet.', {
                          variant: 'success',
                        });
                      })
                      .catch((e) => {
                        enqueueSnackbar(e.message, {
                          variant: 'error',
                          stack: e.stack,
                        });
                      });
                  }}
                  data-testid="button-submit"
                  googleTriggerClassName="ga4-event-email-submit"
                >
                  {t('route.dashboard.email-modal.resend-email', 'E-Mail erneut senden')}
                </Button>
              </div>
            )}
          </div>
        </Paper>
      </animated.div>
    </Modal>
  );
}
