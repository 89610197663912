import { useUser } from '@app/context/UserContext';

export function CelloNotification() {
  const { user } = useUser();

  if (!user?.company.celloReferralCode) return null;

  return (
    <div className="flex flex-col gap-2 mt-4 items-center bg-secondary-root p-4 rounded-lg border-2 border-secondary-700">
      <span className="text-lg font-medium">🥳 YAY! Du wurdest geworben!</span>
      <span>Beim Buchungsvorgang wird dir automatisch zusätzlich 25% Rabatt gewährt!</span>
    </div>
  );
}
