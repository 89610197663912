import { useMessages } from '@app/context/MessageContext';
import { useUser } from '@app/context/UserContext';
import { NavigationSection, OnboardingApps, Topics } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { Tutorial } from '@components/block';
import { Loading } from '@components/ui';
import { PasswordModal } from '@components/ui/PasswordModal/PasswordModal';
import { QuestionnaireChangedModal } from '@components/ui/QuestionnaireChangedModal/QuestionnaireChangeModal';
import { Tab, Tabs } from '@mui/material';
import cx from 'classnames';
import { useEffect } from 'react';
import Confetti from './Info/Confetti';
import { FullCompletionMessage } from './Messages/FullCompletion';
import { NewServiceMessage } from './Messages/NewService';
import { Roadblock } from './Messages/Roadblock';
import { SectionBody } from './SectionBody';
import { SectionInfo } from './SectionInfo';
import { DocumentPaper } from './Topic/Documents/DocumentPaper';
import './route-dashboard.sass';
import { useDashboard } from './useDashboard';

import SideMenuList from '@app/routes/Dashboard/SideMenuList.tsx';
import { Chat } from '@components/block/Chat/Chat';
import { Cello } from '@components/ui/Cello/Cello';
import { ApplicationClass } from '@dieterApi/user/useUserQuery';
import { useUserValueAddMutation } from '@dieterApi/user/useUserValueAddMutation';
import { useTranslation } from 'react-i18next';
import { LimitedCouponOffer } from './Info/LimitedCouponOffer';

const ValidTabValues = [NavigationSection.Overview, NavigationSection.Training, NavigationSection.MultiTenant];

export default function Dashboard() {
  const { t } = useTranslation();
  const { fullcompletion } = useMessages();
  const { user } = useUser();
  const dashboard = useDashboard();
  const { navigation, setNavigation, navigateDashboard } = useNavigation();
  const [addUserValue] = useUserValueAddMutation();
  const { itemListOpen, isMobile, scope, dashboardLocked } = navigation;
  const { activeSection } = navigation;

  useEffect(() => {
    setNavigation((nav) => void (nav.boom = fullcompletion.isOpen));
  }, [fullcompletion.isOpen]);

  // mount and unmount effects to set and unset navigation scope
  useEffect(() => {
    return () => setNavigation((nav) => void (nav.scope = undefined));
  }, []);

  useEffect(() => {
    if (scope !== 'dashboard') {
      setNavigation((nav) => void (nav.scope = 'dashboard'));
    }
  }, [navigation]);

  // This forwards the user to the Onboarding App when it has not been finished.
  useEffect(() => {
    if (
      user &&
      navigation.onboarding.inProgress &&
      (user.isAnonymous || user.source === 'shopify' || user.source === 'mittwald') &&
      !dashboard.isBusy
    ) {
      const existingQuestionnaire = user.questionnaires?.find((q) => q.application.id === navigation.onboarding.appId);

      // there is a special case for the avv and info onboarding, where we need to set a uservalue first and then create the questionnaire
      // with the appropriate uservalue index
      if (navigation.onboarding.appId === OnboardingApps.AVV) {
        if (existingQuestionnaire) {
          dashboard.open(existingQuestionnaire.id);
        } else {
          addUserValue({ variables: { key: 'DATA_PROCESSOR_TYPE', value: 'AVV', createIndex: true } }).then((res) => {
            const userValueIndex = res.data?.addUserValue.index;
            dashboard.create(navigation.onboarding.appId || '', undefined, undefined, userValueIndex);
          });
        }
      } else if (navigation.onboarding.appId === OnboardingApps.INFO) {
        if (existingQuestionnaire) {
          dashboard.open(existingQuestionnaire.id);
        } else {
          addUserValue({
            variables: { key: 'INFOFORM_DESCRIPTION', value: 'allg. Datenschutzhinweise', createIndex: true },
          }).then((res) => {
            const userValueIndex = res.data?.addUserValue.index;
            dashboard.create(navigation.onboarding.appId || '', undefined, undefined, userValueIndex);
          });
        }
      } else {
        existingQuestionnaire
          ? dashboard.open(existingQuestionnaire.id)
          : dashboard.create(navigation.onboarding.appId || '');
      }
    }
    // }
  }, [user, user?.isAnonymous, navigation.onboarding.inProgress, dashboard.isBusy]);

  // if the dashboard is locked, we always forward to the onboarding App topic
  // and then open the DocumentPaper

  useEffect(() => {
    if (dashboardLocked) {
      if (navigation.onboarding.appId || 'app-checkup') {
        const onboardingTopic = user?.topics.find((t) =>
          t.applications.find((a) => a.id === navigation.onboarding.appId)
        );
        navigateDashboard(NavigationSection.Topic, onboardingTopic?.id || Topics.CHECKUP);
        setNavigation(
          (nav) =>
            void (nav.documentPaper = {
              modalOpen: true,
              questionnaire: user?.questionnaires?.find(
                (q) => q.application.id === (navigation.onboarding.appId || OnboardingApps.CHECKUP)
              ),
              index: 0,
            })
        );
      } else if (!user?.hasPassword) {
        setNavigation((nav) => void (nav.passwordModalOpen = true));
      }
    }
  }, [dashboardLocked]);

  // when a user comes fresh from a questionnaire and has not seen the document yet,
  // we move him there, unless he was in a subsection
  useEffect(() => {
    if (navigation.justFinishedQuestionnaireRemoteId && !navigation.activeSubSection) {
      // find the topic of the questionnaire
      const questionnaire = user?.questionnaires?.find(
        (q) => q.questionnaireId === navigation.justFinishedQuestionnaireRemoteId
      );
      const topic = questionnaire?.application.topic;
      if (topic) {
        navigateDashboard(NavigationSection.Topic, topic.id);
        // open the document paper (if it is a document)
        if ([ApplicationClass.Document, ApplicationClass.Analysis].includes(questionnaire.application.class)) {
          setNavigation(
            (nav) =>
              void (nav.documentPaper = {
                modalOpen: true,
                questionnaire: questionnaire,
                index: 0,
              })
          );
        }
      }
    }
  }, [navigation.justFinishedQuestionnaireRemoteId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    navigateDashboard(newValue as NavigationSection);
  };

  const handleCloseSideMenu = () => setNavigation((nav) => void (nav.itemListOpen = !nav.itemListOpen));

  return !user ||
    dashboard.affects('create') ||
    dashboard.affects('open') ||
    (navigation.onboarding.inProgress && user.isAnonymous) ? (
    <div className="mt-24">
      <Loading />
    </div>
  ) : (
    <>
      {/* <Navigation /> */}
      <div
        data-testid="route-dashboard"
        className={cx('2xl:container md:px-7 mb-24', {
          'pb-[65px]': isMobile && false,
        })}
      >
        <div className="lg:hidden">
          <LimitedCouponOffer />
        </div>
        <div
          className={cx('xl:px-[300px] lg:px-[250px] md:pl-[250px] pt-3', {
            'fixed bottom-0 inset-x-0 bg-white z-10 shadow-2xl': isMobile && false,
          })}
        >
          <div
            className={cx(
              '',
              activeSection !== NavigationSection.Status && 'border-b-gray-300',
              isMobile && false ? 'border-t-[1px]' : 'border-b-[1px]'
            )}
          >
            <Tabs
              TabIndicatorProps={{
                className: isMobile && false ? 'top-0 bottom-[unset]' : '',
              }}
              onChange={handleTabChange}
              indicatorColor="primary"
              value={
                ValidTabValues.includes((navigation.activeSection || '') as NavigationSection)
                  ? navigation.activeSection
                  : false
              }
              variant={navigation.isMobile ? 'fullWidth' : undefined}
            >
              <Tab
                value={NavigationSection.Overview}
                disableTouchRipple
                disableRipple
                data-testid="tab-button-overview"
                label={<span className="xl:px-10 px-4 capitalize text-lg">{t('common.overview')}</span>}
              />
              <Tab
                value={NavigationSection.Training}
                label={
                  <span className="xl:px-10 md:px-4 capitalize text-lg" data-testid="sectionInfo-training-link-button">
                    {t('common.topic.employees')}
                  </span>
                }
                data-testid="tab-button-employees"
              />
              {user.hasFeature('MultiTenancy') && (
                <Tab
                  value={NavigationSection.MultiTenant}
                  data-testid="tab-button-tenants"
                  label={<span className="xl:px-10 px-4 capitalize text-lg">{t('dashboard.multitenant')}</span>}
                />
              )}
            </Tabs>
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-full ">
          {/* SectionPanel */}
          <div
            className={cx(
              'xl:min-w-[300px] xl:max-w-[300px] md:min-w-[250px] md:max-w-[250px] ',
              activeSection === NavigationSection.Status && 'd-none'
            )}
          >
            {!isMobile && <SideMenuList />}
          </div>
          {/* BodyPanel */}

          <div
            className={cx(
              ' md:p-12 p-8 grow',

              activeSection == NavigationSection.Status ? 'bg-primary-200 activeSectionStatus' : 'bg-white'
            )}
          >
            <SectionBody dashboard={dashboard} />
          </div>

          {/* InfoPanel */}
          <div className="hidden lg:block xl:min-w-[300px] xl:max-w-[300px] md:min-w-[250px] md:max-w-[250px]">
            <SectionInfo />
          </div>
        </div>

        <Tutorial />
        <DocumentPaper />
        <Roadblock />
        <NewServiceMessage />
        <PasswordModal />
        <QuestionnaireChangedModal />
        <FullCompletionMessage />
        <Confetti
          shouldFire={navigation.boom}
          bigBlast={true}
          onAnimationStart={() => setNavigation((nav) => void (nav.boom = false))}
        />
        <Cello />
        <Chat />
      </div>
      {/* we show the blurry backdrop before user has entered his email */}
      {(!user.email || !user.confirmed) && <div className="fixed h-full w-full backdrop-blur-sm z-40 top-0 left-0" />}
    </>
  );
}
