import { OnboardingAppMapping } from '@app/context/NavigationContext';
import { useUser } from '@app/context/UserContext';
import { OnboardingApps } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { Button, NotificationModal } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

type Snippet = { text: string; pricingParam: string };

export function Roadblock() {
  const { t } = useTranslation();
  const { user } = useUser();
  const history = useHistory();
  const {
    navigation: {
      onboarding,
      roadBlock: { modalOpen, open, close },
    },
  } = useNavigation();

  const appId = onboarding.appId || OnboardingApps.CHECKUP;

  const isWixUser = !!user?.company.wixAppInstanceId;

  const isAlreadyTicketSubscriber = user?.isSubscriber && user.subscription === 'Ticket';

  const handleAlternative = () => {
    close();
  };

  const Snippets: OnboardingAppMapping<Snippet> = {
    [OnboardingApps.CHECKUP]: {
      text: t('dashboard.funnel.roadblock.checkup'),
      pricingParam: '?option=basic',
    },
    [OnboardingApps.DSE]: {
      text: t('dashboard.funnel.roadblock.dse'),
      pricingParam: '?option=ticket&product=dse',
    },
    [OnboardingApps.FACEBOOK]: {
      text: t('dashboard.funnel.roadblock.dse'),
      pricingParam: '?option=ticket&product=dse',
    },
    [OnboardingApps.INSTAGRAM]: {
      text: t('dashboard.funnel.roadblock.dse'),
      pricingParam: '?option=ticket&product=dse',
    },
    [OnboardingApps.LINKEDIN]: {
      text: t('dashboard.funnel.roadblock.dse'),
      pricingParam: '?option=ticket&product=dse',
    },
    [OnboardingApps.TIKTOK]: {
      text: t('dashboard.funnel.roadblock.dse'),
      pricingParam: '?option=ticket&product=dse',
    },
    [OnboardingApps.XING]: {
      text: t('dashboard.funnel.roadblock.dse'),
      pricingParam: '?option=ticket&product=dse',
    },
    [OnboardingApps.TOMs]: {
      text: t('dashboard.funnel.roadblock.toms'),
      pricingParam: '?option=ticket&product=toms',
    },
    [OnboardingApps.IMPRINT]: {
      text: t('dashboard.funnel.roadblock.imprint'),
      pricingParam: '?option=ticket&product=dse',
    },
    [OnboardingApps.VVT]: {
      text: t('dashboard.funnel.roadblock.vvt'),
      pricingParam: '?option=ticket&product=vvt',
    },
    [OnboardingApps.AVV]: {
      text: t('dashboard.funnel.roadblock.avv'),
      pricingParam: '?option=ticket&product=avv',
    },
    [OnboardingApps.INFO]: {
      text: t('dashboard.funnel.roadblock.info'),
      pricingParam: '?option=basic',
    },
  };

  const handleClick = () => {
    isAlreadyTicketSubscriber
      ? history.push('/pricing?option=basic')
      : isWixUser
        ? history.push('/pricing?wix_id=' + user?.company.wixAppInstanceId)
        : history.push('/pricing' + Snippets[appId].pricingParam);
  };

  return (
    <NotificationModal open={modalOpen} onClose={() => {}}>
      <div className="flex flex-col p-[30px]">
        <div className="bg-primary-root -mx-[40px] px-9 py-7 -mt-[40px] flex flex-col text-center gap-4">
          <img src="/assets/images/dieter_claim.png" alt="Info" />
          <span className="text-white font-medium text-lg">{t('dashboard.funnel.roadblock.congratulations')}</span>
        </div>

        <div className="pointer-events-auto bg-white my-5">
          <div className="flex flex-col items-center text-center gap-5">
            <span className="mb-20">
              {isAlreadyTicketSubscriber ? t('dashboard.funnel.roadblock.other_ticket') : Snippets[appId]?.text}
            </span>
            <Button onClick={handleClick} data-testid="button-subscribe">
              {t('dashboard.funnel.roadblock.button')}
            </Button>
            <Link to="#" onClick={handleAlternative} data-testid="button-alternative">
              {t('dashboard.funnel.roadblock.alternative')}
            </Link>
          </div>
        </div>
      </div>
    </NotificationModal>
  );
}
