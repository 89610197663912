import { useEffect } from 'react';

export function CelloAttribution() {
  const celloActive = import.meta.env.VITE_CELLO_ACTIVE === 'true';

  useEffect(() => {
    if (!celloActive) return;
    const script = document.createElement('script');
    script.type = 'module';
    script.src = import.meta.env.VITE_CELLO_ATTRIBUTION_LIB;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  return <></>;
}
