import { OnboardingAppMapping } from '@app/context/NavigationContext';
import { useUser } from '@app/context/UserContext';
import { OnboardingApps } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDashboard } from '../useDashboard';

type Snippet = { text: React.ReactElement };

export function TicketTeaser() {
  const { t } = useTranslation();
  const dashboard = useDashboard();
  const { user } = useUser();
  const {
    navigation: { onboarding },
  } = useNavigation();

  if (!onboarding.appId || onboarding.appId === OnboardingApps.CHECKUP || user?.isSubscriber) return null;

  const Snippets: OnboardingAppMapping<Snippet> = {
    [OnboardingApps.CHECKUP]: {
      text: <></>,
    },
    [OnboardingApps.DSE]: {
      text: (
        <Trans t={t} i18nKey="dashboard.info.ticketteaser.dse">
          Sorge jetzt für einen rechtssicheren Webauftritt und{' '}
          <Link to="/pricing?option=ticket&product=dse">schalte deine Datenschutzerklärung frei!</Link> Damit hast du
          bereits 15 % deiner DSGVO-Pflichten erfüllt
        </Trans>
      ),
    },
    [OnboardingApps.FACEBOOK]: {
      text: (
        <Trans t={t} i18nKey="dashboard.info.ticketteaser.dse">
          Sorge jetzt für einen rechtssicheren Webauftritt und{' '}
          <Link to="/pricing?option=ticket&product=dse">schalte deine Datenschutzerklärung frei!</Link> Damit hast du
          bereits 15 % deiner DSGVO-Pflichten erfüllt
        </Trans>
      ),
    },
    [OnboardingApps.INSTAGRAM]: {
      text: (
        <Trans t={t} i18nKey="dashboard.info.ticketteaser.dse">
          Sorge jetzt für einen rechtssicheren Webauftritt und{' '}
          <Link to="/pricing?option=ticket&product=dse">schalte deine Datenschutzerklärung frei!</Link> Damit hast du
          bereits 15 % deiner DSGVO-Pflichten erfüllt
        </Trans>
      ),
    },
    [OnboardingApps.LINKEDIN]: {
      text: (
        <Trans t={t} i18nKey="dashboard.info.ticketteaser.dse">
          Sorge jetzt für einen rechtssicheren Webauftritt und{' '}
          <Link to="/pricing?option=ticket&product=dse">schalte deine Datenschutzerklärung frei!</Link> Damit hast du
          bereits 15 % deiner DSGVO-Pflichten erfüllt
        </Trans>
      ),
    },
    [OnboardingApps.TIKTOK]: {
      text: (
        <Trans t={t} i18nKey="dashboard.info.ticketteaser.dse">
          Sorge jetzt für einen rechtssicheren Webauftritt und{' '}
          <Link to="/pricing?option=ticket&product=dse">schalte deine Datenschutzerklärung frei!</Link> Damit hast du
          bereits 15 % deiner DSGVO-Pflichten erfüllt
        </Trans>
      ),
    },
    [OnboardingApps.XING]: {
      text: (
        <Trans t={t} i18nKey="dashboard.info.ticketteaser.dse">
          Sorge jetzt für einen rechtssicheren Webauftritt und{' '}
          <Link to="/pricing?option=ticket&product=dse">schalte deine Datenschutzerklärung frei!</Link> Damit hast du
          bereits 15 % deiner DSGVO-Pflichten erfüllt
        </Trans>
      ),
    },
    [OnboardingApps.TOMs]: {
      text: (
        <Trans t={t} i18nKey="dashboard.info.ticketteaser.toms">
          Sorge jetzt für eine rechtssichere Dokumentation deiner TOMs und{' '}
          <Link to="/pricing?option=ticket&product=toms">schalte das Dokument frei!</Link> Damit hast du bereits 15 %
          deiner DSGVO-Pflichten erfüllt
        </Trans>
      ),
    },
    [OnboardingApps.IMPRINT]: {
      text: (
        <Trans t={t} i18nKey="dashboard.info.ticketteaser.imprint">
          Sorge jetzt für einen rechtssicheren Webauftritt und erstelle dir zusätzlich zu deinem Impressum{' '}
          <Link to="#" onClick={() => dashboard.create('app-dse-privacy-policy-create')}>
            eine DSGVO-konforme Datenschutzerklärung!
          </Link>
        </Trans>
      ),
    },
    [OnboardingApps.VVT]: {
      text: (
        <Trans t={t} i18nKey="dashboard.info.ticketteaser.vvt">
          Sorge jetzt für eine rechtssichere Dokumentation deiner Verarbeitungstätigkeiten und{' '}
          <Link to="/pricing?option=ticket&product=vvt">schalte das Dokument frei!</Link> Damit hast du bereits 15 %
          deiner DSGVO-Pflichten erfüllt
        </Trans>
      ),
    },
    [OnboardingApps.AVV]: {
      text: (
        <Trans t={t} i18nKey="dashboard.info.ticketteaser.avv">
          Sorge jetzt für eine ordentliches Auftragsverarbeitungsverhältnis und{' '}
          <Link to="/pricing?option=ticket&product=avv">schalte das Dokument frei!</Link> Damit hast du bereits 15 %
          deiner DSGVO-Pflichten erfüllt
        </Trans>
      ),
    },
    [OnboardingApps.INFO]: {
      text: (
        <Trans t={t} i18nKey="dashboard.info.ticketteaser.info">
          Sorge jetzt für rechtssichere Datenschutzhinweise und{' '}
          <Link to="/pricing?option=ticket&product=avv">schalte das Dokument frei!</Link> Damit hast du bereits 15 %
          deiner DSGVO-Pflichten erfüllt
        </Trans>
      ),
    },
  };

  return <p className="mt-3">{Snippets[onboarding.appId]?.text}</p>;
}
