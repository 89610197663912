import { AnyStep } from '@components/block/Quest/utils';
import { Task } from '@dieterApi/task/useTaskQuery';
import { NavigationState } from './NavigationContext';

export enum DieterHost {
  Local = 'localhost',
  Stage = 'stage.dieter-datenschutz.de',
  Prod = 'app.dieter-datenschutz.de',
}

export enum NavigationSection {
  Overview = 'Dein Datenschutz',
  DoneTasks = 'Abgeschlossene Aufgaben',
  Pricing = 'Preispläne',
  Order = 'Bestellung',
  OrderProcessing = 'Bestellbearbeitung...',
  Confirmation = 'Buchungsbestätigung',
  Status = 'Status',
  DocumentList = 'Dokumente',
  QuestionnaireList = 'Fragebögen',
  TaskList = 'Aufgaben',
  OpenTaskList = 'Offene Aufgaben',
  Training = 'Mitarbeiter',
  MultiTenant = 'Mandanten',
  Topic = 'Topic',
}

export const navSectionPathMapping: Record<NavigationSection, string> = {
  [NavigationSection.Overview]: '/overview',
  [NavigationSection.DoneTasks]: '/done-tasks',
  [NavigationSection.Pricing]: '/pricing',
  [NavigationSection.Order]: '/order',
  [NavigationSection.OrderProcessing]: '/order-processing',
  [NavigationSection.Confirmation]: '/confirmation',
  [NavigationSection.Status]: '/status',
  [NavigationSection.DocumentList]: '/document-list',
  [NavigationSection.QuestionnaireList]: '/questionnaire-list',
  [NavigationSection.TaskList]: '/task-list',
  [NavigationSection.OpenTaskList]: '/open-task-list',
  [NavigationSection.Training]: '/training',
  [NavigationSection.MultiTenant]: '/multi-tenant',
  [NavigationSection.Topic]: '/topic',
};

// inverse mapping
export const pathNavSectionMapping: Record<string, NavigationSection> = Object.entries(navSectionPathMapping).reduce(
  (acc, [key, value]) => {
    acc[value] = key as NavigationSection;
    return acc;
  },
  {} as Record<string, NavigationSection>
);

export enum Topics {
  CHECKUP = 'topic-checkup',
  DSMGMT = 'topic-ds-mgmt',
  DSB = 'topic-dsb',
  DSV = 'topic-dsv',
  AV = 'topic-av',
  TOMs = 'topic-toms',
  DSE = 'topic-dse',
  SOCIAL = 'topic-social',
  INFO = 'topic-info',
  VIDEO = 'topic-video',
  RIGHTS = 'topic-rights',
  EMPLOYESS = 'topic-employees',
  VVZ = 'topic-vvz',
  DSFA = 'topic-dsfa',
  EXP = 'topic-experimental',
}

export enum OnboardingApps {
  CHECKUP = 'app-checkup',
  TOMs = 'app-toms-create',
  DSE = 'app-dse-privacy-policy-create',
  IMPRINT = 'app-dse-imprint-create',
  VVT = 'app-vvz-create',
  AVV = 'app-av-contract-av',
  INFO = 'app-info-create-new',
  FACEBOOK = 'app-social-facebook',
  INSTAGRAM = 'app-social-instagram',
  LINKEDIN = 'app-social-linkedin',
  TIKTOK = 'app-social-tiktok',
  XING = 'app-social-xing',
}

export const topicPathMapping: Record<Topics, string> = {
  [Topics.CHECKUP]: '/checkup',
  [Topics.DSMGMT]: '/dsmgmt',
  [Topics.DSB]: '/dsb',
  [Topics.DSV]: '/dsv',
  [Topics.AV]: '/av',
  [Topics.TOMs]: '/toms',
  [Topics.DSE]: '/dse',
  [Topics.SOCIAL]: '/social',
  [Topics.INFO]: '/info',
  [Topics.VIDEO]: '/video',
  [Topics.RIGHTS]: '/rights',
  [Topics.EMPLOYESS]: '/employees',
  [Topics.VVZ]: '/vvz',
  [Topics.DSFA]: '/dsfa',
  [Topics.EXP]: '/experimental',
};

// inverse mapping
export const pathTopicMapping: Record<string, Topics> = Object.entries(topicPathMapping).reduce(
  (acc, [key, value]) => {
    acc[value] = key as Topics;
    return acc;
  },
  {} as Record<string, Topics>
);

export enum NavigationSubsection {
  ContractPartners = 'av-contract-partners',
  SubContracts = 'av-subcontracts',
  TechnologyScan = 'dse-technology-scan',
  Incidents = 'dsv-incidents',
  Requests = 'rights-request',
  ConsentForms = 'info-consent-forms',
  InfoForms = 'info-forms',
  Usercentrics = 'dse-usercentrics',
}

export const subSectionPathMapping: Record<NavigationSubsection, string> = {
  [NavigationSubsection.ContractPartners]: '/contract-partners',
  [NavigationSubsection.TechnologyScan]: '/technology-scan',
  [NavigationSubsection.SubContracts]: '/subcontracts',
  [NavigationSubsection.Incidents]: '/incidents',
  [NavigationSubsection.Requests]: '/requests',
  [NavigationSubsection.ConsentForms]: '/consent-forms',
  [NavigationSubsection.InfoForms]: '/info-forms',
  [NavigationSubsection.Usercentrics]: '/consentmanagement',
};

// inverse mapping
export const pathSubSectionMapping: Record<string, NavigationSubsection> = Object.entries(subSectionPathMapping).reduce(
  (acc, [key, value]) => {
    acc[value] = key as NavigationSubsection;
    return acc;
  },
  {} as Record<string, NavigationSubsection>
);

export const initialNavigationState: NavigationState = {
  isDieterHost: true,
  scope: undefined,
  activeSection: undefined,
  activeTopic: undefined,
  activeSubSection: undefined,
  onboarding: {
    appId: undefined,
    inProgress: false,
  },
  dashboardLocked: false,
  languageDisclaimerDismissed: {},
  passwordModalOpen: false,
  stepIndex: -1,
  steps: [] as Array<AnyStep>,
  onAdvance: () => {},
  itemListOpen: false,
  expertModeEnabled: false,
  documentPaper: {
    modalOpen: false,
    index: 0,
    questionnaire: undefined,
  },
  questionnaireHasChanged: {
    modalOpen: false,
    questionnaire: undefined,
  },
  roadBlock: {} as any,
  phoneCTAdismissed: false,
  limitedOfferDismissed: false,
  mfaNotificationDismissed: false,
  dismissedMessages: [],
  isMobile: false,
  isPrintView: false,
  animateBadge: false,
  questionnaireWrapup: false,
  revisitTutorialSeen: false,
  showTopicCompleteConfirmation: {},
  taskTutorials: {},
  taskOpen: {
    tutorial: '',
    confirm: '',
  },
  animate: false, // animate anything and then close
  newTasks: {},
  dismissedTasks: {},
  ignoredTasks: {},
  newDocuments: {},
  questTask: {} as Task,
  progress: 0,
  boom: false,
  showSavedAnimation: false,
  hubspot: {
    showChat: false,
    chatIsOpen: false,
    chatIsLoaded: false,
  },
  chatsViewed: [],
  consent: {
    hubspot: false,
    sentry: false,
  },
};
