import { Box, Tab, Tabs, ThemeProvider, createTheme } from '@mui/material';
import r2wc from '@r2wc/react-to-web-component';
import { useEffect, useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../styles/tailwind.css';
import { Toggle } from '../Toggle/Toggle';

import 'swiper/css';
import 'swiper/css/navigation';

export interface CustomerMode {
  value: ModeValues;
  label: string;
  shortLabel: string;
  products: PricingProduct[];
}

export type TenantVariant = 0 | 1 | 5 | 10;

export interface PricingProduct {
  name: string;
  isAgency: boolean;
  isDpo: boolean;
  value: string;
  description: string;
  free?: boolean;
  price?: Price;
  tenantPrice?: Record<TenantVariant, Price>;
  featureTitle: string;
  features?: string[];
  pricingLink: {
    month: string;
    year: string;
  };
  withTest: boolean;
  preferred: boolean;
  selectableProducts?: TicketProduct[];
}

export interface TicketProduct {
  label: string;
  value: TopicValues;
  description: string;
  features: string[];
  price?: Price;
}

interface Price {
  month: number;
  year: number;
  discount: number;
}

interface IProps {
  queryoption?: string | null;
  user?: User;
  selectedProductValue?: TopicValues;
  locale?: string;
  querymode?: string | null;
  onQueryModeChange?(queryMode: string): void;
  inApp?: boolean;
  wixAppInstanceId?: string;
  wixPrice?: WixPrice;
  onUpgrade?(props: UpgradeProps): void;
}

import { ModeValues, TopicValues, UpgradeProps } from '@app/routes/Pricing/Pricing';
import { User } from '@dieterApi/user/useUserQuery';
import { WixPrice } from '@dieterApi/wix/useWixPriceQuery';
import { CelloNotification } from './CelloNotification';
import { ProductCard } from './ProductCard';
import { SwiperNavButtons } from './SwiperNavButtons';
import { useLocalizedTranslation } from './Translation';

export function PricingTable({
  queryoption,
  user,
  selectedProductValue = 'dse',
  locale = 'de',
  querymode,
  onQueryModeChange,
  inApp = false,
  wixAppInstanceId,
  wixPrice,
  onUpgrade,
}: IProps) {
  const { t: tl } = useLocalizedTranslation(locale);
  const principalCompany = user?.companies?.find((company) => company.isPrincipal);
  const preferredMode = principalCompany?.isAgency ? 'agencies' : principalCompany?.isDpo ? 'dpos' : 'companies';
  const [mode, setMode] = useState<ModeValues>((querymode || preferredMode || 'companies') as ModeValues);
  const [selectedProduct, setSelectedProduct] = useState<TicketProduct | undefined>(undefined);

  const [selectedTenants, setSelectedTenants] = useState<TenantVariant>(0);
  const userEmail = user?.email;
  // build checkout query params
  const emailParam = userEmail ? `&email=${userEmail}` : '';
  const localeParam = locale ? `&locale=${locale}` : '';
  const tenantParam = selectedTenants ? `&tenants=${selectedTenants}` : '';
  const ticketTopicParam = selectedProduct?.value ? `&topic=${selectedProduct?.value}` : '';
  const isWixUser = !!wixAppInstanceId;
  const wixParam = isWixUser ? `&wix_id=${wixAppInstanceId}` : '';
  const stripeCustomerIdParam = principalCompany?.stripeCustomerId
    ? `&stripe_customer_id=${principalCompany?.stripeCustomerId}`
    : '';
  const forCompanyParam =
    user?.company.isPrincipal === false && user?.company.id ? `&forcompany=${user?.company.id}` : '';

  const clientOrigin = import.meta.env.VITE_CLIENT_ORIGIN;
  const checkoutUrl = clientOrigin || 'https://app.dieter-datenschutz.de';
  const customerModes: CustomerMode[] = [
    {
      value: 'companies',
      label: tl('customer_mode.companies'),
      shortLabel: tl('customer_mode_short.companies'),
      products: [
        {
          name: 'Freemium',
          value: 'freemium',
          isAgency: false,
          isDpo: false,
          description: tl('freemium.description'),
          featureTitle: '',
          free: true,
          features: [tl('freemium.f1'), tl('freemium.f2'), tl('freemium.f3'), tl('freemium.f4'), tl('freemium.f5')],
          preferred: false,
          withTest: false,
          pricingLink: {
            month: 'https://app.dieter-datenschutz.de/onboarding',
            year: 'https://app.dieter-datenschutz.de/onboarding',
          },
        },

        {
          name: tl('ticket.name'),
          value: 'ticket',
          isAgency: false,
          isDpo: false,
          description: tl('ticket.description'),

          featureTitle: tl('ticket.featureTitle'),

          preferred: false,
          withTest: false,
          pricingLink: {
            month:
              `${checkoutUrl}/api/checkout?product=ticket&trial=false&interval=month` +
              emailParam +
              localeParam +
              ticketTopicParam +
              wixParam +
              stripeCustomerIdParam +
              forCompanyParam,
            year:
              `${checkoutUrl}/api/checkout?product=ticket&trial=false&interval=year` +
              emailParam +
              localeParam +
              ticketTopicParam +
              wixParam +
              stripeCustomerIdParam +
              forCompanyParam,
          },
          selectableProducts: [
            {
              label: tl('ticket.dse.label'),
              value: 'dse',
              description: tl('ticket.dse.description'),
              features: [
                tl('ticket.dse.f1'),
                tl('ticket.dse.f2'),
                tl('ticket.dse.f3'),
                tl('ticket.dse.f4'),
                tl('ticket.dse.f5'),
                tl('ticket.dse.f6'),
                tl('ticket.dse.f7'),
              ],
              price: {
                month: isWixUser ? wixPrice?.monthly || 9 : 9,
                year: isWixUser ? wixPrice?.yearly || 7 : 7,
                discount: 22,
              },
            },

            ...(!isWixUser
              ? ([
                  {
                    label: tl('ticket.toms.label'),
                    value: 'toms',
                    description: tl('ticket.toms.description'),
                    features: [tl('ticket.toms.f1'), tl('ticket.toms.f2'), tl('ticket.toms.f3')],
                    price: {
                      month: 19,
                      year: 15,
                      discount: 22,
                    },
                  },
                  {
                    label: tl('ticket.vvt.label'),
                    value: 'vvt',
                    description: tl('ticket.vvt.description'),
                    features: [tl('ticket.vvt.f1'), tl('ticket.vvt.f2'), tl('ticket.vvt.f3')],
                    price: {
                      month: 19,
                      year: 15,
                      discount: 22,
                    },
                  },
                  {
                    label: tl('ticket.avv.label'),
                    value: 'avv',
                    description: tl('ticket.avv.description'),
                    features: [
                      tl('ticket.avv.f1'),
                      tl('ticket.avv.f2'),
                      tl('ticket.avv.f3'),
                      tl('ticket.avv.f4'),
                      tl('ticket.avv.f5'),
                    ],
                    price: {
                      month: 19,
                      year: 15,
                      discount: 22,
                    },
                  },
                ] as TicketProduct[])
              : []),
          ],
        },
        {
          name: 'Basic',
          value: 'basic',
          isAgency: false,
          isDpo: false,
          description: tl('basic.description'),
          price: {
            month: 59,
            year: 29,
            discount: 50,
          },
          featureTitle: tl('basic.featureTitle'),
          features: [
            tl('basic.f1'),
            tl('basic.f2'),

            tl('basic.f4'),
            tl('basic.f5'),

            tl('basic.f7'),
            tl('basic.f8'),
            tl('basic.f9'),
            tl('basic.f10'),
            tl('basic.f11'),
            tl('basic.f12'),
            tl('basic.f13'),
            tl('basic.f14'),
            tl('basic.f15'),
            tl('basic.f16'),
            tl('basic.f17'),
            tl('basic.f6'),
            tl('basic.f3'),
            tl('basic.f18'),
          ],
          preferred: true,
          withTest: true,
          pricingLink: {
            month:
              `${checkoutUrl}/api/checkout?product=basic&trial=true&interval=month` +
              emailParam +
              localeParam +
              stripeCustomerIdParam +
              forCompanyParam,
            year:
              `${checkoutUrl}/api/checkout?product=basic&trial=true&interval=year` +
              emailParam +
              localeParam +
              stripeCustomerIdParam +
              forCompanyParam,
          },
        },
        {
          name: 'Premium',
          value: 'premium',
          isAgency: false,
          isDpo: false,
          description: tl('premium.description'),
          price: {
            month: 99,
            year: 79,
            discount: 20,
          },
          featureTitle: tl('premium.featureTitle'),
          features: [
            tl('premium.f1'),
            tl('premium.f2'),
            tl('premium.f3'),
            tl('premium.f4'),
            tl('premium.f5'),
            tl('premium.f6'),
            tl('premium.f7'),
          ],
          preferred: false,
          withTest: true,
          pricingLink: {
            month:
              `${checkoutUrl}/api/checkout?product=premium&trial=true&interval=month` +
              emailParam +
              localeParam +
              stripeCustomerIdParam +
              forCompanyParam,
            year:
              `${checkoutUrl}/api/checkout?product=premium&trial=true&interval=year` +
              emailParam +
              localeParam +
              stripeCustomerIdParam +
              forCompanyParam,
          },
        },
        {
          name: 'Premium +',
          value: 'premiumplus',
          isAgency: false,
          isDpo: false,
          description: tl('premiumplus.description'),
          price: {
            month: 199,
            year: 149,
            discount: 25,
          },
          featureTitle: tl('premiumplus.featureTitle'),
          features: [
            tl('premiumplus.f1'),
            tl('premiumplus.f2'),
            tl('premiumplus.f3'),
            tl('premiumplus.f4'),
            tl('premiumplus.f5'),
          ],
          preferred: false,
          withTest: true,
          pricingLink: {
            month:
              `${checkoutUrl}/api/checkout?product=premiumplus&trial=true&interval=month` +
              emailParam +
              localeParam +
              stripeCustomerIdParam +
              forCompanyParam,
            year:
              `${checkoutUrl}/api/checkout?product=premiumplus&trial=true&interval=year` +
              emailParam +
              localeParam +
              stripeCustomerIdParam +
              forCompanyParam,
          },
        },
      ],
    },
    {
      value: 'agencies',
      label: tl('customer_mode.agencies'),
      shortLabel: tl('customer_mode_short.agencies'),
      products: [
        {
          name: 'Agency Basic',
          isAgency: true,
          isDpo: false,
          value: 'basic',
          description: tl('agency_basic.description'),
          price: {
            month: 119,
            year: 69,
            discount: 42,
          },
          featureTitle: tl('agency_basic.featureTitle'),
          features: [
            tl('agency_basic.f1'),
            tl('agency_basic.f2'),
            // tl('agency_basic.f3'),
            tl('agency_basic.f4'),
            tl('agency_basic.f5'),
            tl('agency_basic.f6'),
            tl('agency_basic.f7'),
          ],
          preferred: true,
          withTest: true,
          pricingLink: {
            month:
              `${checkoutUrl}/api/checkout?product=basic&trial=true&interval=month&mode=agencies` +
              emailParam +
              localeParam +
              stripeCustomerIdParam +
              forCompanyParam,
            year:
              `${checkoutUrl}/api/checkout?product=basic&trial=true&interval=year&mode=agencies` +
              emailParam +
              localeParam +
              stripeCustomerIdParam +
              forCompanyParam,
          },
        },
        {
          name: 'Agency Premium',
          value: 'premium',
          isAgency: true,
          isDpo: false,
          description: tl('agency_premium.description'),
          price: {
            month: 169,
            year: 129,
            discount: 25,
          },
          featureTitle: tl('agency_premium.featureTitle'),
          features: [
            tl('agency_premium.f1'),
            tl('agency_premium.f2'),
            tl('agency_premium.f3'),
            tl('agency_premium.f4'),
          ],
          preferred: false,
          withTest: true,
          pricingLink: {
            month:
              `${checkoutUrl}/api/checkout?product=premium&trial=true&interval=month&mode=agencies` +
              emailParam +
              localeParam +
              stripeCustomerIdParam +
              forCompanyParam,
            year:
              `${checkoutUrl}/api/checkout?product=premium&trial=true&interval=year&mode=agencies` +
              emailParam +
              localeParam +
              stripeCustomerIdParam +
              forCompanyParam,
          },
        },
      ],
    },
    {
      value: 'dpos',
      label: tl('customer_mode.dpos'),
      shortLabel: tl('customer_mode_short.dpos'),
      products: [
        {
          name: 'DSB',
          isAgency: false,
          isDpo: true,
          value: 'basic',
          description: tl('dpo_basic.description'),
          price: {
            month: 119,
            year: 69,
            discount: 42,
          },
          tenantPrice: {
            0: {
              month: 0,
              year: 0,
              discount: 0,
            },
            1: {
              month: 59,
              year: 29,
              discount: 50,
            },
            5: {
              month: 56,
              year: 27.5,
              discount: 50,
            },
            10: {
              month: 51,
              year: 25,
              discount: 50,
            },
          },
          featureTitle: tl('dpo_basic.featureTitle'),
          features: [
            tl('dpo_basic.f1'),
            tl('dpo_basic.f2'),
            tl('dpo_basic.f3'),
            tl('dpo_basic.f4'),
            tl('dpo_basic.f5'),
            tl('dpo_basic.f6'),
            tl('dpo_basic.f7'),
          ],
          preferred: true,
          withTest: true,
          pricingLink: {
            month:
              `${checkoutUrl}/api/checkout?product=basic&trial=true&interval=month&mode=dpos` +
              emailParam +
              localeParam +
              tenantParam +
              forCompanyParam,
            year:
              `${checkoutUrl}/api/checkout?product=basic&trial=true&interval=year&mode=dpos` +
              emailParam +
              localeParam +
              tenantParam +
              forCompanyParam,
          },
        },
      ],
    },
  ];

  const currentCustomerMode = useMemo(() => customerModes.findIndex(({ value }) => value === mode), [mode, locale]);

  const products = customerModes[currentCustomerMode].products.filter((p) => !p.free || !inApp) as PricingProduct[];

  useEffect(() => {
    if (mode === 'companies') {
      setSelectedProduct(
        products
          .find((product) => product.value === 'ticket')
          ?.selectableProducts?.find((opt) => opt.value === (selectedProductValue || 'dse'))
      );
    }
  }, [selectedProductValue, mode, locale]);

  useEffect(() => {
    if (!user?.company.celloReferralCode) return;
  }, [user?.company.celloReferralCode]);

  const [isActive, setIsActive] = useState(true);

  const handleCustomerTypeChange = (_: React.SyntheticEvent, newValue: number) => {
    const val = customerModes[newValue].value;
    setMode(val);
    onQueryModeChange?.(val);
  };
  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  // Define a local theme for the specific component
  const localTheme = createTheme({
    palette: {
      primary: {
        main: '#a95ecd',
      },
    },
    typography: {
      fontFamily: ['DieterRubik', 'Rubik', 'Arial'].join(','),
    },
  });

  let initialSlide = products.findIndex((product) => queryoption === product.value);
  if (initialSlide === -1) {
    initialSlide = 2;
  }

  const isMobile = window.innerWidth < 768;

  return (
    <Box className="container text-left">
      {!isWixUser && (
        <ThemeProvider theme={localTheme}>
          <Box sx={{ mb: 4, borderBottom: 1, borderColor: 'divider' }} className={'hidden md:block'}>
            <Tabs value={currentCustomerMode} onChange={handleCustomerTypeChange} centered>
              {customerModes.map(({ value, label }) => (
                <Tab key={value} label={label} className="grow" />
              ))}
            </Tabs>
          </Box>
          <Box sx={{ mb: 4, borderBottom: 1, borderColor: 'divider' }} className={'block md:hidden'}>
            <Tabs value={currentCustomerMode} onChange={handleCustomerTypeChange} centered>
              {customerModes.map(({ value, shortLabel }) => (
                <Tab key={value} label={shortLabel} className="grow" />
              ))}
            </Tabs>
          </Box>
        </ThemeProvider>
      )}
      <div className="flex flex-col items-center ">
        <div id="duration-toggle" className="flex justify-between items-center gap-4 w-full">
          <div className="flex-1 text-right font-medium">
            <span className="text-gray-500 text-sm md:text-base">{tl('common.monthly_billing')}</span>
          </div>
          <div className="flex justify-center">
            <Toggle active={isActive} onChange={handleToggle} />
          </div>
          <div className="flex-1 text-left text-primary-root font-medium text-sm md:text-base">
            <span>{tl('common.yearly_billing')}</span>
          </div>
        </div>
        <CelloNotification />

        <Swiper
          spaceBetween={20}
          slidesPerView={isMobile ? 1.2 : 1}
          initialSlide={initialSlide}
          centeredSlides={true}
          centerInsufficientSlides={true}
          breakpoints={{
            460: {
              slidesPerView: 1.5,
              spaceBetween: 20,
              centeredSlides: false,
            },
            768: {
              slidesPerView: 2.2,
              spaceBetween: 20,
              centeredSlides: false,
            },
            1024: {
              slidesPerView: 2.5,
              spaceBetween: 28,
              centeredSlides: false,
            },
            1280: {
              slidesPerView: 3.5,
              spaceBetween: 28,
              centeredSlides: false,
            },
            1400: {
              slidesPerView: 4,
              spaceBetween: 28,
              centeredSlides: false,
            },
          }}
          grabCursor
          className="w-full mb-12 !pt-8 sm:!pt-0"
        >
          <SwiperNavButtons />
          {products
            .filter((p) => !isWixUser || p.value === 'ticket')
            .map((product) => (
              <SwiperSlide key={product.name} className="py-6">
                <ProductCard
                  product={product}
                  user={user}
                  mode={mode}
                  interval={isActive ? 'year' : 'month'}
                  showButton={!product.free || !inApp}
                  queryOption={queryoption}
                  selectedProduct={selectedProduct}
                  onProductSelect={setSelectedProduct}
                  selectedTenants={selectedTenants}
                  onTenantSelect={setSelectedTenants}
                  isUpgradeHighlight={selectedProduct?.value === selectedProductValue && product.value === 'ticket'}
                  locale={locale}
                  isWixUser={isWixUser}
                  onUpgrade={onUpgrade}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </Box>
  );
}

const PricingTableComponent = r2wc(PricingTable, {
  // @ts-expect-error
  props: { locale: 'string', querymode: 'string', queryoption: 'string' },
});

if (!customElements.get('pricing-table')) {
  customElements.define('pricing-table', PricingTableComponent);
}
