import { OnboardingAppMapping } from '@app/context/NavigationContext';
import { OnboardingApps } from '@app/context/constants';
import { useNavigation } from '@app/hooks/useNavigation';
import { Button } from '@components/ui';
import CheckIcon from '@mui/icons-material/Check';
import { Modal, Paper, StepIconProps, StepLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useMessages } from '@app/context/MessageContext';
import { useUser } from '@app/context/UserContext';
import { Slide } from '@components/ui/Animations/Slide';
import { useTranslation } from 'react-i18next';
import './tutorial.sass';
import { useTutorialSteps } from './useTutorialSteps';

export function Tutorial() {
  const { user } = useUser();
  const { basicSteps, dseSteps, tomsSteps, imprintSteps } = useTutorialSteps();

  const {
    navigation: { onboarding },
  } = useNavigation();
  const appId = onboarding.appId || OnboardingApps.CHECKUP;

  const allSteps: OnboardingAppMapping<typeof basicSteps> = {
    [OnboardingApps.CHECKUP]: basicSteps,
    [OnboardingApps.DSE]: dseSteps,
    [OnboardingApps.FACEBOOK]: dseSteps,
    [OnboardingApps.INSTAGRAM]: dseSteps,
    [OnboardingApps.LINKEDIN]: dseSteps,
    [OnboardingApps.TIKTOK]: dseSteps,
    [OnboardingApps.XING]: dseSteps,
    [OnboardingApps.TOMs]: tomsSteps,
    [OnboardingApps.IMPRINT]: imprintSteps,
    [OnboardingApps.VVT]: basicSteps,
    [OnboardingApps.AVV]: basicSteps,
    [OnboardingApps.INFO]: basicSteps,
  };

  return (
    <TutorialStepper
      steps={user?.isSubscriber ? basicSteps : allSteps[appId]}
      moveToPricing={false} //{appTitle !== 'Check-Up' && !user?.isSubscriber}
    />
  );
}

// code snippet from https://mui.com/material-ui/react-stepper/

interface Step {
  label: string;
  content: JSX.Element;
}

interface StepperProps {
  steps: Step[];
  moveToPricing: boolean;
}

function TutorialStepper({ steps, moveToPricing }: StepperProps) {
  const { t } = useTranslation();
  const { tutorial } = useMessages();
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const [skipped, setSkipped] = useState(new Set<number>());

  useEffect(() => {
    tutorial.setContext(['tutorial']);
  }, []);

  const isStepOptional = (step: number) => {
    return false;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (moveToPricing) {
        tutorial.close();
        history.push('/pricing');
      } else {
        tutorial.close();
      }
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal open={tutorial.isOpen} onClose={tutorial.close}>
      <Slide in={tutorial.isOpen}>
        <Paper className="absolute md:top-1/2 left-1/2 md:-translate-y-1/2 -translate-x-1/2 w-full md:h-[650px] flex flex-col md:min-h-[500px] md:w-[750px] pointer-events-auto h-full supports-[height:100cqh]:h-[100cqh]">
          <Box
            className="grow"
            sx={{ width: '100%', minHeight: 'inherit', display: 'flex', flexDirection: 'column', padding: '20px' }}
          >
            <Stepper activeStep={activeStep}>
              {steps.map((step, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = <Typography variant="caption">{t('common.optional', 'Optional')}</Typography>;
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={step.label} {...stepProps}>
                    <StepLabel {...labelProps} StepIconComponent={StepIcon}></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Box sx={{ flex: '1 1 auto', mt: 2, mb: 1 }}>{steps[activeStep].content}</Box>
          </Box>

          <Box className="dtTutorial__footer" sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box className="flex-1 flex">
              {activeStep > 0 && (
                <Button
                  className="skipButton"
                  invisible={true}
                  color="inherit"
                  onClick={handleBack}
                  data-testid="tutorial-skip-button"
                >
                  {t('common.back', 'Zurück')}
                </Button>
              )}
            </Box>
            <Box className="flex-2" />
            <Box className="flex-1 flex">
              {activeStep < steps.length - 1 && (
                <Button
                  className="skipButton"
                  invisible={true}
                  color="inherit"
                  onClick={tutorial.close}
                  data-testid="tutorial-skip-button"
                >
                  {t('common.skip', 'Überspringen')}
                </Button>
              )}
            </Box>
            <Box className="flex-2">
              <Button onClick={handleNext} data-testid="tutorial-next-button">
                {activeStep === steps.length - 1
                  ? moveToPricing
                    ? t('common.about-the-pricing-plans', 'Zu den Preisplänen')
                    : t('common.let-us-go', 'Los geht`s')
                  : t('common.continue', 'Weiter')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Slide>
    </Modal>
  );
}

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <div className="h-6 w-6 flex items-center justify-center">
      {completed ? (
        <CheckIcon className="text-primary-root" fontSize="small" />
      ) : active ? (
        <div className="rounded-full h-2 w-2 bg-primary-root" />
      ) : (
        <div className="rounded-full h-2 w-2 bg-slate-300" />
      )}
    </div>
  );
}
